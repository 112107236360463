import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonButton, IonLoading, IonModal } from '@ionic/react';
import LoginModal from '../../components/LoginModal';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import './Tab3.css';
import { useUserData } from '../../components/UserDataFetcher';
import { useHistory } from 'react-router-dom';
import ExcelImportButton from '../../components/ExcelImportButton';
import { getPortalUrl } from '../../components/stripeService';
import { FirebaseApp } from 'firebase/app';
import AddUserModel from '../../components/AddAuthorizedEmail'; 
import { useScanner } from '../../contexts/ScannerContext';

const Tab3: React.FC<{ app: FirebaseApp }> = ({ app }) => {
  const { stopScanner, isScannerActive } = useScanner();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const userData = useUserData();
  const dealerId = userData?.dealerIds || 'defaultDealerId';
  const history = useHistory();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  useEffect(() => {
    if (isScannerActive) {
      stopScanner();
    }
  }, [isScannerActive, stopScanner]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(app), (currentUser) => {
      setIsUserLoggedIn(!!currentUser);
    });
    return () => unsubscribe();
  }, [app]);

  const handleSignOut = async () => {
    try {
      await signOut(getAuth(app));
      setIsUserLoggedIn(false);
      history.push('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleManageSubscriptions = async () => {
    setShowLoading(true);
    getPortalUrl(app)
      .then(url => (window.location.href = url))
      .catch(error => console.error('Failed to get portal URL:', error))
      .finally(() => setShowLoading(false));
  };

  const goToSettings = () => {
    history.push('/settings');
  };

  const handleRunWMScrape = async (forceRefresh: boolean = false) => {
    console.log("Dealer ID:", dealerId, "Force Refresh:", forceRefresh);

    if (!dealerId) {
      console.error("No dealerId found. Cannot run WMScrape.");
      alert("Error: No dealer ID available!");
      return;
    }

    setShowLoading(true);

    try {
      const response = await fetch(
        "https://us-central1-cabinet-logger.cloudfunctions.net/runWMScrape",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ dealerId, forceRefresh }), // ✅ Send forceRefresh in the request
        }
      );

      if (!response.ok) {
        throw new Error("Failed to run WMScrape.");
      }

      const result = await response.json();
      console.log("WMScrape successful:", result);
      alert("Scrape started successfully!");
    } catch (error) {
      console.error("Error running WMScrape:", error);
      alert("Failed to start scrape. Please try again.");
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        {!isUserLoggedIn && (
          <LoginModal 
            onLoginSuccess={() => setIsUserLoggedIn(true)} 
            showModal={false} 
            setShowModal={() => {}} 
          />
        )}

        {isUserLoggedIn && (
          <div className="navigate-button-container">
            <IonButton expand="block" onClick={handleSignOut}>Sign Out</IonButton>
            <IonButton expand="block" onClick={goToSettings}>Settings</IonButton>
            <IonButton expand="block" onClick={handleManageSubscriptions}>
              Manage Subscriptions
            </IonButton>
            <IonButton expand="block" onClick={() => handleRunWMScrape()}>
              Run WM Scrape
            </IonButton>
            <IonButton expand="block" color="danger" onClick={() => handleRunWMScrape(true)}>
              Force Refresh Scrape
            </IonButton>
            <ExcelImportButton dealerId={dealerId} />
          </div>
        )}

        <IonLoading isOpen={showLoading} message={'Please wait...'} />
        
        <IonModal isOpen={showAddUserModal} onDidDismiss={() => setShowAddUserModal(false)}>
          <AddUserModel isOpen={showAddUserModal} onClose={() => setShowAddUserModal(false)} />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
